import React from "react";
import { graphql, Link } from "gatsby";
import Aligner from "../components/comp-layouts/Aligner";
import styled from "styled-components";
import Layout from "../components/global/layout";
import SEO from "../components/global/seo";
import Container from "../components/comp-layouts/Container";
import Owl from "../components/comp-layouts/Owl";
import Button from "../components/Button";
import theme from "../theme/theme.json";

const PostTemplate = ({ data, pageContext }) => {
  const { previous, next } = pageContext;
  return (
    <>
      {/* {data.markdownRemark.frontmatter.shareimage.publicURL && (
        <SEO
          title={data.markdownRemark.frontmatter.title}
          description={data.markdownRemark.frontmatter.description}
          slug={`/publications${data.markdownRemark.fields.slug}`}
          image={data.markdownRemark.frontmatter.shareimage.publicURL}
        />
      )}
      {!data.markdownRemark.frontmatter.shareimage.publicURL && (
        <SEO
          title={data.markdownRemark.frontmatter.title}
          description={data.markdownRemark.frontmatter.description}
          slug={`/publications${data.markdownRemark.fields.slug}`}
        />
      )} */}
      <SEO
        title={data.markdownRemark.frontmatter.title}
        description={data.markdownRemark.frontmatter.description}
        slug={`/publications${data.markdownRemark.fields.slug}`}
      />
      <Layout>
        <Container mt="60px" mb="60px">
          <Owl>
            <PubTitle>{data.markdownRemark.frontmatter.title}</PubTitle>
            <Date>{data.markdownRemark.frontmatter.date}</Date>
            <Authors>{data.markdownRemark.frontmatter.authors}</Authors>
            <div
              dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
            />
            <Aligner justify="center">
              <Button
                exLink="true"
                target="_blank"
                rel="noopener"
                to={data.markdownRemark.frontmatter.link}
              >
                Access Article
              </Button>
            </Aligner>
          </Owl>
          {/* {!previous && (
            <FirstBlogListNavContainer>
              <Button link="true" to={`/publications${next.fields.slug}`}>
                Next Article
              </Button>
            </FirstBlogListNavContainer>
          )}

          {previous && (
            <BlogListNavLinkContainer>
              {previous && (
                <Button
                  link="true"
                  to={`/publications${previous.fields.slug}`}
                  rel="prev"
                >
                  Previous Article
                </Button>
              )}
              {next && (
                <Button
                  link="true"
                  to={`/publications${next.fields.slug}`}
                  rel="prev"
                >
                  Next Article
                </Button>
              )}
            </BlogListNavLinkContainer>
          )} */}
        </Container>
      </Layout>
    </>
  );
};

export default PostTemplate;

export const publicationPostQuery = graphql`
  query PublicationPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      fields {
        collection
        slug
      }
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM Do, YYYY")
        authors
        description
        # shareimage {
        #   publicURL
        # }
        alt
        link
      }
    }
  }
`;

const FirstBlogListNavContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

const BlogListNavLinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

const PubTitle = styled.h1`
  font-size: ${theme.fs["3xl"]};
`;

const PubMeta = styled.div`
  font-weight: bold;
  border: 3px solid ${theme.col.primary.low};
  padding: 10px;
  width: max-content;
`;

const Date = styled.time`
  font-weight: bold;
  display: inline-block;
  background: ${theme.col.primary.dark};
  color: ${theme.col.bw.w};
  margin-right: 15px;
  padding: 6px 12px;
  font-size: ${theme.fs.sm};
`;

const Authors = styled.span`
  font-weight: bold;
  display: inline-block;
  color: ${theme.col.primary.low};
  font-size: ${theme.fs.sm};
`;
